<template>
  <div class="container mt-4">
    <div class="row ">
      <img src="./iheader.png" alt="header" width="100%" />
    </div>
    <div class="row">


      <table class="table table-sm m-0 headertable">
        <tr class="border-bottom ">
          <td class="text-center" colspan="4">
            فاتورة ضريبية
          </td>
        </tr>
        <tr>
          <td class="text-right">شركة ابناء عبدالرحمن على البصيلى</td>
          <td class="text-right">اسم العــميل</td>
          <td class="text-right">{{ invoice.invoice_id }}</td>
          <td class="text-right">رقم الفاتورة</td>
        </tr>
        <tr>
          <td class="text-right">1010152299</td>
          <td class="text-right">رقم السجل التجارى</td>
          <td class="text-right">
            {{ invoice.created_at | moment("DD MMMM YYYY") }}
          </td>
          <td class="text-right">تاريخ الفاتورة</td>
        </tr>
        <tr>
          <td class="text-right"><u>‫‪300047805100003‬‬</u></td>
          <td class="text-right">الرقم الضريبى</td>
          <td class="text-right">{{ invoice.ref_invoice }}</td>
          <td class="text-right">الرقم المرجعي الداخلي</td>
        </tr>
        <tr>
          <td class="text-right"></td>
          <td class="text-right">‫</td>
          <td colspan="1" class="text-right">
            من {{ hijri(invoice.invoice_start) }} من {{ invoice.invoice_start | moment("YYYY/MM/DD") }}
          </td>
          <td class="text-right">مدة الفاتورة</td>
        </tr>
        <tr>
          <td colspan="3" class="text-right">
            الى {{ hijri(invoice.invoice_end) }} الى {{ invoice.invoice_end | moment("YYYY/MM/DD") }}
          </td>
          <td class="text-right"> </td>
        </tr>
        <tr>
          <td class="text-right"> {{ cus_name }}</td>
          <td class="text-right"> اســم الشـركة</td>
          <td class="text-right">{{ hc_address }}</td>
          <td class="text-right">نــوع الفاتورة</td>
        </tr>
        <tr>
          <td class="text-right"><u>{{ cus_vat }}</u></td>
          <td class="text-right">الرقم الضريبى</td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td colspan="2">
            <span class="float-left">
              <vue-qrcode :value="invoice.qrcode" :options="{ width: 150 }"></vue-qrcode>
            </span>
          </td>
          <td></td>
          <td></td>

        </tr>
      </table>
    </div>
    <div class="row">
      <table class="table table-sm m-0">
        <tbody>
          <tr>
            <td class="text-center">{{ invoice.contract_no }}</td>
            <td class="text-right">رقـــــم الــصــادر</td>
          </tr>
          <tr>
            <td class="text-center">{{ invoice.description }}</td>
            <td class="text-right">الــبـيــان</td>
          </tr>
          <!-- <tr>
        <th class="text-center">المبلغ</th>
        <th class="text-center">التاريخ الميلادي</th>
        <th class="text-center">التاريخ الهجري</th>
        <th class="text-center">البـيـان</th>
        <th class="text-right" width="4px"></th>
      </tr>
      <tr v-for="(value, index) in items" :key="index">
        <td  class="text-center">
          {{ comma(value.amount) }}
        </td>
        <td class="text-center"> 
          {{value.dategeo}}

        </td>
        <td class="text-center">
           {{value.datehijri}}
           </td>
        <td class="text-right">
          {{ value.item_arabic }}
        </td>
        <td class="text-center">{{ increment_index(index) }}</td>
      </tr> -->

          <tr>
            <td class="text-center">{{ invoice_total_amount }}</td>
            <td class="text-right">قــيــمة المـــســتــخــلــص</td>
          </tr>

          <tr>
            <td class="text-center">{{ invoice_vat_amount }}</td>
            <td class="text-right">ضريـبــة القيـمة المضافة %15</td>

          </tr>

          <tr>
            <td class="text-center">{{ invoice_gross_amount }}</td>
            <td class="text-right">الاجــمــالي بعد الضــريــبــة</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <table class="table table-sm m-0">
        <tbody>
          <tr>
            <td class="text-right">
              القيمة بالحروف: {{ invoice.amount_words_ar }}
            </td>
          </tr>
          <tr>
            <td colspan="5" class="text-left">
              <div style="margin-left: 1em; height:100px;  width:200px; border-bottom:solid 1px black;">
              </div>
              <div style="width:200px;">
                <h2 class="text-right"> مـــديــر النقد </h2>
                <h2 class="text-right">أ / يعقوب فضل الله </h2>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row">
      <img src="./ifooter.png" alt="header" width="100%">
    </div>
  </div>
</template>

<script>
const VueQrcode = require("@chenfengyuan/vue-qrcode");
import HRMService from "../../../../services/hrm-service";
export default {
  name: "SalesInvoiceMilling",
  components: {
    VueQrcode: VueQrcode,
  },
  data: () => {
    return {
      invoice: {},
      items: [],
      invoice_total_amount: 0,
      invoice_vat_amount: 0,
      invoice_gross_amount: 0,
      hc_address: '',
      cus_name: '',
      cus_vat: ''
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    comma(data) {
      return Number(parseFloat(data).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 });
    },
    hijri(data) {
      let dateTime = new Date(data);
      let dayOfYear = Math.floor((dateTime - new Date(dateTime.getFullYear(), 0, 0)) / (1000 * 60 * 60 * 24))
      let hijriDate = ((dateTime.getFullYear() - 621.5643) * 365.24225 + dayOfYear - 1) / 354.36707
      let hijriYear = Math.floor(hijriDate)
      let hijriMonth = Math.ceil((hijriDate - Math.floor(hijriDate)) * 354.36707 / 29.530589)
      let hijriDay = Math.floor((hijriDate - Math.floor(hijriDate)) * 354.36707 % 29.530589)
      return hijriYear + '/' + hijriMonth + '/' + hijriDay;

    },
    retrieve(id) {
      HRMService.getOrderItems(id)
        .then((response) => {
          this.invoice = response.data["sales"][0];
          this.items = response.data["items"];

          this.invoice_total_amount = this.comma(this.invoice.total_amount);
          this.invoice_vat_amount = this.comma(this.invoice.vat_amount);
          this.invoice_gross_amount = this.comma(this.invoice.gross_amount);;
          this.invoice_rentention_amount = this.comma(this.invoice.retention_amount);
          this.invoice_net_amount = this.comma(this.invoice.net_amount)

          if (this.invoice.invoice_type == "Progress Service Order") {
            this.hc_address = "الدفــعـات";
          } else if (this.invoice.invoice_type == "Final Payment") {
            this.hc_address = "الاستــقـطاع";
          } else if (this.invoice.invoice_type == "Other") {
            this.hc_address = "اخــري";
          } else {
            this.hc_address = "";
          }


          if (this.invoice.customer == "Al-Ahsa") {
            this.cus_name = "شركة المطاحن الاولى ـ فرع الأحـــســـاء"
            this.cus_vat = "‫‪310151719500003‬‬";
          } else if (this.invoice.customer == "Qaseem") {
            this.cus_name = "شركة المطاحن الاولى ـ فرع القصيم";
            this.cus_vat = "‫‪310151719500003‬‬";
          } else if (this.invoice.customer == "Tabuk") {
            this.cus_name = "شركة المطاحن الاولى ـ فرع تبوك";
            this.cus_vat = "‫‪310151719500003‬‬";
          } else if (this.invoice.customer == "Jeddah") {
            this.cus_name = "شركة المطاحن الاولى ـ فرع جدة";
            this.cus_vat = "‫‪310151719500003‬‬";
          }
          else if (this.invoice.customer == "Al-Kharj") {
            this.cus_name = "شركة المطاحن الرابعة ـ فرع الخرج";
            this.cus_vat = "310180106200003";
          }
          else if (this.invoice.customer == "Al-Dammam") {
            this.cus_name = "شركة المطاحن الرابعة ـ فرع الدمام";
            this.cus_vat = "310180106200003";
          }
          else if (this.invoice.customer == "Medina-Munawara") {
            this.cus_name = "شركة المطاحن الرابعة ـ فرع المدينة المنورة";
            this.cus_vat = "310180106200003";
          }
          else if (this.invoice.customer == "Al-Riyadh") {
            this.cus_name = "شركة المطاحن الثانية ـ فرع الرياض";
            this.cus_vat = "302009083900003";
          }
          else if (this.invoice.customer == "Jizan") {

            this.cus_name = "شركة المطاحن الثانية ـ فرع جـازان";
            this.cus_vat = "302009083900003";
          }
          else if (this.invoice.customer == "Hail") {

            this.cus_name = "شركة المطاحن الثانية ـ فرع حائل";
            this.cus_vat = "302009083900003";
          }
          else if (this.invoice.customer == "Jamoom") {

            this.cus_name = "شركة المطاحن الثالثة ـ فرع الجموم";
            this.cus_vat = "310165241100003";
          }
          else if (this.invoice.customer == "Al-Jouf") {

            this.cus_name = "شركة المطاحن الثالثة ـ فرع الجوف";
            this.cus_vat = "310165241100003";
          }
          else if (this.invoice.customer == "Khamis-Musheet") {

            this.cus_name = "شركة المطاحن الثالثة ـ فرع خميس مشيط";
            this.cus_vat = "310165241100003";
          }




        })
        .catch((e) => {
          console.log(e);
        });
    },
    increment_index(key) {
      return Number(key) + 1;
    },
  },
};
</script>
<style scoped>
span {
  font-size: 15px;
}

.border-bottom {
  border-bottom: 2px solid black !important;
}

.line-height {
  line-height: 10px;
}

.br {
  border: black solid 2px;
}

.table {
  border: 2px solid black;
}

.table tr td {
  border: 2px solid black !important;
  /* border: none !important; */
  /* margin: 4px; */
  font-size: 18px !important;
}

.table tr th {
  border: 2px solid black;
  font-size: 18px !important;
}

.headertable tr td {
  border: none !important;
  font-weight: bold;
}
</style>

